<template>
  <div class="upload-container">
    <div v-for="(item, index) in showImages" :key="index" class="images">
      <el-image v-if="index < max" :src="item" :style="`width:${width}px;height:${height}px;`" fit="cover"/>
      <div class="mask">
        <div class="actions" :style="`width:${width}px;height:${height}px;`">
                    <span title="预览" @click="preview(index)">
                        <el-icon><el-icon-zoom-in/></el-icon>
                    </span>
          <span v-show="!disabled" title="移除" @click="remove(index)">
                        <el-icon><el-icon-delete/></el-icon>
                    </span>
          <span v-show="showImages.length > 1" title="左移" :class="{'disabled': index == 0}"
                @click="move(index, 'left')">
                        <el-icon><el-icon-back/></el-icon>
                    </span>
          <span v-show="showImages.length > 1" title="右移" :class="{'disabled': index == showImages.length - 1}"
                @click="move(index, 'right')">
                        <el-icon><el-icon-right/></el-icon>
                    </span>
        </div>
      </div>
    </div>
    <div v-show="showImages.length < max" class="images-upload dir-top-nowrap main-between"
         :style="`width:${width}px;height:${height}px;`">
      <div class="select-btn box-grow-0 main-center cross-center" @click="handleClickSelect"
           v-if="selectMode === 'all'"> 选择
      </div>
      <QuickUpload
        :action="action"
        :headers="headers"
        :moduleName="moduleName"
        :data="data"
        :name="name"
        :size="size"
        :width="width"
        :height="height"
        :ext="ext"
        :max="1"
        :disabled="disabled"
        class="box-grow-1"
        @on-success="handleSelect"
        v-if="selectMode != 'select'"
      >
        <div :style="{height:`${height- (selectMode === 'all' ? 35:0)}px`}"
             class="image-slot dir-top-nowrap main-center cross-center box-grow-1">
          <svg-icon icon="el-icon-plus"/>
        </div>
      </QuickUpload>
      <div v-else class="image-slot dir-top-nowrap main-center cross-center box-grow-1" @click="handleClickSelect">
        <svg-icon icon="el-icon-plus"/>
      </div>
    </div>

    <dialog-attachment
      :headers="headers"
      :action="action"
      :moduleName="moduleName"
      :data="data"
      :name="name"
      :size="size"
      :ext="ext"
      :max="imageMax"
      @select="handleSelect"
      class=""
      ref="attachment"
    ></dialog-attachment>


    <div v-if="!notip" class="el-upload__tip">
      <div style="display: block;">
        <el-alert
          :title="`上传图片支持 ${ ext.join(' / ') } 格式，单张图片大小不超过 ${ size }MB，建议图片尺寸为 ${width}*${height}，且图片数量不超过 ${ max } 张`"
          type="info" show-icon :closable="false"/>
      </div>
    </div>
    <el-image-viewer v-if="uploadData.imageViewerVisible" :url-list="showImages"
                     :initial-index="uploadData.dialogImageIndex"
                     @close="previewClose"/>

  </div>
</template>

<script setup>
import DialogAttachment from "./dialog-attachment"
import QuickUpload from "./quick-upload"
import {computed, getCurrentInstance, defineEmits, ref, onMounted} from 'vue'
import ImageUpload from "@/components/ImageUpload";

const {proxy} = getCurrentInstance()

const props = defineProps({
  action: {
    type: String,
    required: true
  },
  headers: {
    type: Object,
    default: () => {
    }
  },
  data: {
    type: Object,
    default: () => {
    }
  },
  name: {
    type: String,
    default: 'file'
  },
  url: {
    type: [Array, String],
    default: () => []
  },
  max: {
    type: Number,
    default: 10
  },
  size: {
    type: Number,
    default: 4
  },
  width: {
    type: Number,
    default: 100
  },
  height: {
    type: Number,
    default: 100
  },
  placeholder: {
    type: String,
    default: ''
  },
  notip: {
    type: Boolean,
    default: true
  },
  selectMode: {
    type: String,//select upload all
    default: 'all'
  },
  ext: {
    type: Array,
    default: () => ['jpg', 'png', 'gif', 'bmp']
  },
  moduleName: {
    type: String,
    default: 'admin',
  },
  disabled: {
    type: Boolean,
    defaut: false
  }
})

const emit = defineEmits(['update:url', 'on-success'])

const imageMax = computed(() => {
  if (typeof props.url === 'string') {
    return 1
  }
  return props.max - props.url.length
})

const showImages = computed(() => {
  if (typeof props.url === 'string') {
    if (props.url) {
      return [
        props.url
      ]
    }
    return []
  }
  return props.url
})

const uploadData = ref({
  dialogImageIndex: 0,
  imageViewerVisible: false,
  progress: {
    preview: '',
    percent: 0
  }
})

// 预览
function preview(index) {
  uploadData.value.dialogImageIndex = index
  uploadData.value.imageViewerVisible = true
}

// 关闭预览
function previewClose() {
  uploadData.value.imageViewerVisible = false
}

// 移除
function remove(index) {
  let url = props.url
  if (typeof url === 'string') {
    url = ''
  } else {
    url.splice(index, 1)
  }

  emit('update:url', url)
}

// 移动
function move(index, type) {
  let url = props.url
  if (type == 'left' && index != 0) {
    url[index] = url.splice(index - 1, 1, url[index])[0]
  }
  if (type == 'right' && index != url.length - 1) {
    url[index] = url.splice(index + 1, 1, url[index])[0]
  }
  emit('update:url', url)
}

function handleSelect(images) {
  let url = props.url
  if (!Array.isArray(images)) {
    images = [images]
  }
  images.forEach((img) => {
    if (typeof url === 'string') {
      url = img.image
    } else {
      url.push(img.image)
    }
  })

  // console.log('-----update:url---',url)
  emit('update:url', url)
}


const attachment = ref(null)

function handleClickSelect() {
  attachment.value.openDialog()
}

</script>

<style lang="scss" scoped>
.upload-container {
  line-height: initial;
  display: flex;
  flex-wrap: wrap;
}

.images {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  overflow: hidden;

  .el-image {
    display: block;
  }

  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    transition: all 0.3s;

    .actions {
      width: 100px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      span {
        width: 50%;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: all 0.1s;

        &.disabled {
          color: #999;
          cursor: not-allowed;
        }

        &:hover:not(.disabled) {
          transform: scale(1.5);
        }

        .el-icon {
          font-size: 24px;
        }
      }
    }
  }

  &:hover .mask {
    opacity: 1;
  }
}


.images-upload {

  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.images-upload:hover {
  border: 1px dashed #409EFF;
}

.select-btn {
  height: 35px;
  border-bottom: 1px dashed #d9d9d9;
  margin: 0 7px;
  color: #909399;
}

.select-btn:hover {
  border-bottom: 1px dashed #409EFF;
  color: #409EFF;
}


.image-slot {
  //width: 100%;
  //height: 100%;
  color: #909399;
  background-color: transparent;

  :hover {
    color: #409EFF;
  }

  i {
    font-size: 26px;
  }


}

</style>
