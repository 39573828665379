<template>
  <default-field
    v-model="value"
    v-bind="labelProps"
    @reset="resetField"
  >
    <el-time-picker
      v-model="value"
      :disabled-hours="disabled_hours"
      :disabled-minutes="disabled_minutes"
      v-bind="attrs"
      :isRange="isRange"
    >
    </el-time-picker>
  </default-field>
</template>

<script>
import {computed, ref, useAttrs, onMounted} from "vue";
import {baseProps} from '../Composition/FormField';

const makeRange = (start, end) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}
export default {
  name: 'TimeField',
  props: {
    ...baseProps,
    isRange: {
      type: Boolean,
      default: false
    },
    disabledHours: {
      type: [Array],
      default: () => []
    },
    disabledMinutes: {
      type: [Array, Object],
      default: () => []
    },
    disabledSeconds: {
      type: [Array, Object],
      default: () => []
    }
  },
  setup(props) {
    /**
     * 初始化值
     */
    const initialValue = () => {
      let d = new Date();
      let str = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      if (props.isRange) {
        console.log('---date', new Date(str + " " + props.default[0]), new Date(str + " " + props.default[1]))
        console.log('---date def', new Date())
        console.log('---date def', str + " " + props.default[0], str + " " + props.default[1])
        return !(props.default === undefined || props.default === null || props.default === '' || props.default.length !== 2)
          ? [new Date(str + " " + props.default[0]), new Date(str + " " + props.default[1])]
          : [new Date(), new Date()];
      }
      return !(props.default === undefined || props.default === null || props.default === '')
        ? new Date(str + " " + props.default)
        : new Date();
    };

    const attrs = useAttrs();
    const value = ref(initialValue());

    const def = ref(null);

    const disabled_hours = () => {
      return props.disabledHours
    }

    const disabled_minutes = (hour) => {
      if (!props.disabledMinutes.length) {
        return []
      }
      if (props.disabledMinutes[hour]) {
        return makeRange(props.disabledMinutes[hour][0], props.disabledMinutes[hour][1])
      } else if (props.disabledMinutes['all']) {
        return makeRange(props.disabledMinutes['all'][0], props.disabledMinutes['all'][1])
      }
      return []
    }


    /**
     * label
     */
    const labelProps = computed(() => {
      return {
        ...props,
        ...attrs
      };
    });


    /** 接管验证 start ******/
    const validate = () => {
      return false;
    };

    /** 处理表单重置 **/
    const resetField = (data) => {
      console.log('resetField')
      if (data) {
        value.value = data;
        return;
      }
      value.value = initialValue();
    };

    onMounted(() => {
      // value.value = initialValue();
    })

    /**
     * blur 事件验证数据
     */
    const handleBlur = () => {
      def.value.validate('blur');
    };

    /**
     * 填充formArr
     * @param formData
     */
    const fill = (formData) => {

      if (props.isRange) {
        formData[props.column] = [
          value.value[0].getHours() + ":" + value.value[0].getMinutes() + ":" + value.value[0].getSeconds(),
          value.value[1].getHours() + ":" + value.value[1].getMinutes() + ":" + value.value[1].getSeconds(),
        ];
        return formData;
      }
      formData[props.column] = value.value.getHours() + ":" + value.value.getMinutes() + ":" + value.value.getSeconds();
      return formData;
    };


    return {
      handleBlur,
      resetField,
      validate,
      disabled_hours,
      disabled_minutes,
      fill,
      labelProps,
      attrs,
      value,
      def,
    }
  }
}
</script>
<style scoped lang="scss">
.qk-slider {
  :deep(.el-slider__button-wrapper) {
    z-index: 1;
  }
}
</style>
