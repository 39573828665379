<template>
  <default-field
    v-model="fieldValue"
    v-bind="labelProps"
    @reset="resetField"
    @listen="handleListen"
    ref="def"
  >
    <el-select
      v-model="value"
      v-bind="fieldAttrs"
      :remote-method="remoteMethod"
      :loading="loading"
      @blur="handleBlur"
    >
      <template v-for="(option,index ) in optionList">
        <template v-if="option.options">
          <el-option-group :key="index" :label="option.label">
            <el-option
              v-for="item in option.options"
              :key="item.value"
              v-bind="item"
            >
              <component
                :is="optionDisplay.component"
                v-if="optionDisplay"
                :option="item"
                v-bind="optionDisplay.attribute"
              />
            </el-option>

          </el-option-group>
        </template>
        <el-option
          v-else
          :key="index"
          v-bind="option"
        >
          <component
            :is="optionDisplay.component"
            v-if="optionDisplay"
            :option="option"
            v-bind="optionDisplay.attribute"
          />
        </el-option>
      </template>

    </el-select>
  </default-field>
</template>

<script setup>
  import DefaultField from './DefaultField';
  import {
    useAttrs,
    defineProps,
    ref,
    onMounted,
    computed, defineExpose,
  } from 'vue';

  import { baseProps } from '../Composition/FormField';

  const props = defineProps({
    ...baseProps,
    options: {
      type: [Array],
      default: () => []
    },
    optionDisplay: {
      type: [Object, Boolean],
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    params:{
      type: Object,
      default: () => {}
    },
  });

  const attrs = useAttrs();
  const value = ref('');
  const def = ref(null);


  const loading = ref(false);
  const optionList = ref([]);

  const fieldValue = computed(() => {
    // console.log('-----fieldValue------select---',value.value)
    return value.value
  })

  /**
   * label
   */
  const labelProps = computed(() => {
    return {
      ...props,
      ...attrs
    };
  });

  const isDisabled = ref(false)
  const handleListen = (event) => {
    if (event && event.action) {
      switch (event.action) {
        case 'disabled':
          isDisabled.value = event.params;
          break;
        case 'load':
          remoteMethod(event);
          break;
      }
    }
  }
  const fieldAttrs = computed(() => {
    return {
      ...attrs,
      disabled: isDisabled.value,
    };
  });

  onMounted(() => {
    value.value = initialValue();
    optionList.value = props.options
    isDisabled.value = props.disabled
  })


  /** 处理表单重置 **/
  const resetField = (data) => {

    if (data) {
      value.value = data;
      return;
    }
    value.value = resetValue();
  };

  /**
   * 初始化值
   */
  const initialValue = () => {
    return !(props.default === undefined || props.default === null)
      ? props.default
      : '';
  };

  const resetValue = () => {
    return '';
  };

  /**
   * blur 事件验证数据
   */
  const handleBlur = () => {
    def.value.validate('blur');
  };


  /** 接管验证  ******/
  const validate = () => {
    return false;
  };


  const remoteMethod = (query) =>  {
    if (query !== '') {
      loading.value = true
      const params = props.params || {}
      Quick.request({
        method: 'post',
        url: props.url,
        data: Object.assign({},params,{ query: query })
      }).then(res => {
        loading.value = false
        optionList.value = res.data
        if(res.data && res.data.length){

          let hasVal = false
          for(let i = 0 ; i < res.data.length; i++){
            if(res.data[i].value === value.value ){
              hasVal = true
              break
            }
          }
          if(!hasVal){
            value.value = '';
          }
        }else{
          value.value = '';
        }

      }).catch(() => {
        loading.value = false
      })
    } else {
      optionList.value = []
    }
  }



</script>
<style scoped lang="scss">
  :deep(.el-select){
    width:99.5%;
  }
</style>
